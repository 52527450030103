const dictApi = {
    //字典列表
    dictList: '/dict/list',
    // 删除字典
    dictDel: '/dict/delete',
    //新建字典
    dictAdd: '/dict/add',
    //编辑字典
    dictEdit: '/dict/edit',

    //字典项列表
    dictListItem: '/dict/listItem',
    //删除字典项
    dictDelItem: '/dict/deleteItem',
    //新建字典项
    dictAddItem: '/dict/addItem',
    //编辑字典项
    dictEditItem: '/dict/editItem',
}


export default dictApi