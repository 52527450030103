import request from "@/utils/request";
import dictApi from '@/const/apiUrl/dict'
import method from "@/const/request/requestMethod";

//字典列表
export function dictList(searchData) {
    return request({
        url: dictApi.dictList,
        method: method.GET,
        data: searchData
    })
}
//删除字典
export function dictDel(params) {
    return request({
        url: dictApi.dictDel,
        method: method.GET,
        data: params
    })
}
//新建字典
export function dictAdd(params) {
    return request({
        url: dictApi.dictAdd,
        method: method.POST,
        data: params
    })
}
//编辑字典
export function dictEdit(params) {
    return request({
        url: dictApi.dictEdit,
        method: method.POST,
        data: params
    })
}


//字典项列表
export function dictListItem(searchData) {
    return request({
        url: dictApi.dictListItem,
        method: method.GET,
        data: searchData
    })
}
//删除字典项
export function dictDelItem(params) {
    return request({
        url: dictApi.dictDelItem,
        method: method.GET,
        data: params
    })
}
//新建字典项
export function dictAddItem(params) {
    return request({
        url: dictApi.dictAddItem,
        method: method.POST,
        data: params
    })
}
//编辑字典项
export function dictEditItem(params) {
    return request({
        url: dictApi.dictEditItem,
        method: method.POST,
        data: params
    })
}
